import { createRoutesFromChildren, matchRoutes, Routes, useLocation, useNavigationType } from 'react-router-dom';
import { createReactRouterV6Options, getWebInstrumentations, initializeFaro, faro, ReactIntegration } from '@grafana/faro-react';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';

const nodeEnvToGrafanaConfigurationURL: Record<string, string> = {
  fat: 'https://faro-collector-prod-eu-west-2.grafana.net/collect/e44bd095b543b75f8c40448c76a2ffd2',
  uat: 'https://faro-collector-prod-eu-west-2.grafana.net/collect/5721140bf3f9c4c49fc14429b028f442',
  production: 'https://faro-collector-prod-eu-west-2.grafana.net/collect/fce8b462ddbf3b8bbaf4ea102a08c5e2'
};

const nodeEnvToGrafanaConfigurationAppName: Record<string, string> = {
  fat: 'mysc-weu-fat',
  uat: 'mysc-weu-uat',
  production: 'mysc-weu-production'
};

const environment = process.env.REACT_APP_ENVIRONMENT ?? 'production';
const url = nodeEnvToGrafanaConfigurationURL[environment] ?? nodeEnvToGrafanaConfigurationURL.production;
const name = nodeEnvToGrafanaConfigurationAppName[environment] ?? nodeEnvToGrafanaConfigurationAppName.production;

initializeFaro({
  url,
  app: {
    name,
    version: '1.0.0',
    environment
  },

  instrumentations: [
    ...getWebInstrumentations(),

    new TracingInstrumentation(),

    new ReactIntegration({
      router: createReactRouterV6Options({
        createRoutesFromChildren,
        matchRoutes,
        Routes,
        useLocation,
        useNavigationType
      })
    })
  ]
});

export const setGrafanaUserSessionAttributes = (userId: string, username: string, country?: string | null): void => {
  faro.api.setUser({
    id: userId,
    username,
    attributes: {
      country: country ?? 'null'
    }
  });
};
